<template>
  <div>
    <h3 ref="intro">
      What is Probability?
    </h3>
    <p>
      Probability, stated in simple terms, is a way to determine how likely (or unlikely) any given event is.
      The concept of probability is useful when dealing with random experiments that can yield multiple outcomes.
      For example you can think of rolling of a dice as a random experiment that can yield six possible outcomes &mdash; 1, 2, 3, 4, 5 or 6.
      Numerically, the probability of getting a given outcome is expressed by a number between 0 and 1.
      The higher the probability of a given event, the greater the likelihood of happening of that event.
      Probability of 0 implies that the outcome is unlikely while a probability of 1 implies that the outcome is certain.
    </p>
    <h3 ref="sol">
      Probability of an Event
    </h3>
    <p>
      Calculating the probability of a single event is straightforward.
      Let's consider a random experiment (say tossing of a coin) that can yield multiple outcomes (heads or tails).
      Let's say the number of all possible outcomes is <i>n</i>. Now assume that the ways in which a specific outcome (say getting heads) can be achieved is <i>m</i>.
      Then the probability of the event (say A) that the given outcome is obtained, often denoted by \(P(A)\), is given by:
      $$ P(A) = \frac{m}{n}$$
      The probability that event A doesn't happen is given by:
      $$P(\bar{A}) = 1- P(A) = 1- \frac{m}{n}$$
    </p>
    <h3 ref="nature">
      Probability of Multiple Events
    </h3>
    <p>
      There are many scenarios in which we may have to calculate the probability of occurrence of multiple events.
      These events can be independent of each other, or dependent or mutually exclusive.
      Let's consider a random experiment (such as rolling a pair of dice) that comprises of two events A and B. Let's say event A corresponds to getting an outcome 2 on one of the dice and event B corresponds to getting an outcome 4 on the other.
      <ul class="a">
        <li>
          <h5> Probability of EITHER A OR B  </h5>
          Probability that either event A or B happens, denoted by \(P(A&cup;B)\), is given by:
          $$P(A &cup; B) = P(A) + P(B) - P(A&cap;B) $$
          where \(P(A)\) is the probability of occurrence of event A, \(P(B)\) is the probability of occurrence of event B, and \(P(A&cap;B)\) is the joint probability of events A and B.
        </li>
        <li>
          <h5> Joint Probability of A AND B </h5>
          Joint probability of two events A and B, often denoted by \(P(A&cap;B)\), is the probability that both the events A and B take place. For two events A and B that can take place independent of each other (i.e. the outcome of one event doesn't affect
          the outcome of other), the joint probability \(P(A&cap;B)\) is simply the product of their individual probabilities.
          $$  P(A &cap;B)= P(A)\times P(B)$$
          For two events that are mutually exclusive (i.e. they cannot happen together), the joint probability is zero.
          $$  P(A&cap;B)= 0$$
        </li>
        <li>
          <h5> Conditional Probability P(A &vert; B) </h5>
          Conditional probability is the probability of the occurrence of event A conditional upon the occurrence of event B.
          Stated in simple terms &ndash; conditional probability gives the chances of happening of event A when event B has already happened.
          For example &ndash; consider rolling of a pair of dice. The probability of getting a sum of 6 (event A) while getting a 2 on at least one of the dices (event B).
        </li>
      </ul>
    </p>
    <h3 ref="graph">
      MagicGraph | Probability of Any Given Sum during Rolling of a Pair of Dice
    </h3>
    <p>
      This MagicGraph explains the concept of probability in a visually interactive fashion through the example of rolling of a pair of dice .
    </p>
    <h5> To Get Started: </h5>
    <p>
      Imagine rolling a pair of dice. Your goal is to calculate the probability of getting any given sum.
      Here is the way to think about and solve this problem. Start with finding the total of all possible outcomes that you can obtain when you roll a pair of dice.
      Say that number is \(N\).<br><br>
      Next, find the number of outcomes that will give the desired sum. Say there are \(n\) such outcomes.<br><br>
      Then the probability of getting the desired sum is simply \(P = n/N\).<br><br>
      During rolling of a pair of dice, the total of all possible outcomes is \(6 \times 6 =36\) (each dice can give you six possible outcomes).<br>
      To obtain the number of ways in which you can obtain a given sum, enter the sum in the blue box. Then, tap at the hands to roll the dice.
      The MagicGraph will show all the outcomes with give you the desired sum total.
      The MagicGraph will also calculate the probability of getting that sum in fraction and percentage.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'ProbabilityTheory',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Probability Theory';
    this.$store.commit('navigation/changeTitle', 'ProbabilityTheory');
    this.$store.commit('navigation/changeMenu', 'ProbabilityTheory');
    let newTopics = [
      {title: 'What is Probability?', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Probability of a Single Event', img:'/assets/number-2.svg', action: () => this.goto('sol')},
      {title: 'Probability of Multiple Events', img:'/assets/number-3.svg', action: () => this.goto('nature')},
      {title: 'PlayGraph: Rolling of a Pair of Dice', img:'/assets/touch.svg', action: () => this.goto('graph')}
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo: {
          title: 'Probability Theory',
        //  titleTemplate: '%s - Learn interactively',
          meta: [ {vmid:'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {vmid:'description',name: 'description', content: 'Learn interactively how to calculate probability'}
                ]
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
